import React, { useState } from "react";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const ModalRemoveComment = ({ actionModal, openModal, handleSuccess, entity }) => {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>Remover o comentário?</h5>
                </div>
            }
        >
            <p className="text-center">Esta ação é permanente, deseja prosseguir?</p>
            <button
                className="mb-4 ml-2 bg-roxo_oficial text-white py-2 px-5 rounded-md float-left mt-10"
                onClick={() => actionModal()}
            >
                Cancelar
            </button>
            <button
                className="mb-4 bg-red text-white py-2 px-5 rounded-md float-right mt-10"
                onClick={() => {
                    setLoading(true);
                    requests
                        .removeFreightComment(entity.identifier)
                        .then(response => {
                            toast.success("Comentário removido com sucesso!");
                            setLoading(false);
                            handleSuccess();
                        })
                        .catch(error => {
                            setLoading(false);
                            toast.error("Ocorreu um erro ao remover o comentário, atualize a página e tente novamente.");
                        });
                }}
            >
                Remover {loading && <ClipLoader size="13" color="white" />}
            </button>
        </Modal>
    );
};
export default ModalRemoveComment;
